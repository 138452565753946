<template>
  <div class="main">
    <p>批量处理</p>
    <a-row>
        <a-col align="end" :span="24">
            <a-space :size="18">
              <a-upload
                :disabled="loadingBtn"
                :showUploadList="false"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
                :customRequest="customRequest"
              >
                <a-button :loading="loadingBtn" type="danger" style="background-color: rgb(205 0 25 / 85%);" ><a-icon type="cloud-upload" /> 上传批量订单</a-button>
              </a-upload>
              
              <a-button @click="downLoad"><a-icon type="cloud-download"/> 下载批量订单模板</a-button>
            </a-space>
        </a-col>
        <a-col :span="24" class="hint"><b>请先下载批量订单模板，在模板中填入相应的订单信息。在Excel中先搜索开票品类关键词并选中，规格不需要的话可以不填，有需要的则自定义填入，个体户名称通过关键词搜索下拉选中，输入货款金额，选中税率，并输入商品数量 。每个订单创建一行，然后保存文件，并通过页面点击【上传批量订单】。平台会自动对订单进行批量创建，耐心等待几分钟，系统会将本次批量处理的结果进行反馈。</b></a-col>

        <a-col :span="24">
            <a-table :columns="columns" :loading="loadingTable" :data-source="data" rowKey="id" :pagination="{
              size: 'middle',
              total: total,
              current: form.pageNum,
              pageSize: form.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '50', '100'],
              showTotal: (total) => `共有 ${total} 条数据`,
              showQuickJumper: true,
              onShowSizeChange: (current, pageSize) => {
                form.pageSize = pageSize
                form.pageNum = 1
                this.getList()
              },
              onChange: (pageNum) => {
                form.pageNum = pageNum
                this.getList()
              }
            }">
                <template slot="batchStatus" slot-scope="text">
                    <a-space :class="batchStatusIcon[text]">
                      <a-icon :type="batchStatusIcon[text]" />
                      <span>{{batchStatus[text]}}</span>
                    </a-space>
                </template>
                <template slot="reqExcelUrl" slot-scope="text">
                                       <a :href="text" target="_blank">下载</a>
                </template>
                <template slot="remark" slot-scope="text, row">
                    <span v-if="!row.respExcelUrl">{{text}}</span>
                    <a :href="row.respExcelUrl" v-else target="_blank">{{row.respExcelName}}</a>
                </template>
            </a-table>
        </a-col>
    </a-row>
  </div>
</template>

<script>
import { batchList, batchAdd } from "@/api/buyerCenter";
import { uploadPhoto } from '@/api/utils'
import { batchStatus } from "@/utils/constArr";
const columns = [
    {
        title: "批量上传时间",
        dataIndex: "createdTime"
    },
    {
        title: "原始文件",
        dataIndex: "reqExcelUrl",
        scopedSlots: { customRender: "reqExcelUrl" }
    },
    {
        title: "状态",
        scopedSlots: { customRender: "batchStatus" },
        dataIndex: "batchStatus"
    },
    {
        title: "反馈结果",
        scopedSlots: { customRender: "remark" },
        dataIndex: "remark"
    },
]
const batchStatusIcon = {
  'WAIT': 'loading',
  'PROCESSING': 'sync',
  'FAIL': 'close-circle',
  'PART_FAIL': 'close-circle',
  'SUCCESS': 'check-circle'
}
export default {
  data() {
    return {
        batchStatusIcon,
        batchStatus,
        data: [],
        columns,
        loadingTable: false,
        loadingBtn: false,
        total: 0,
        form: {
          purchaserId: this.$route.query.entityId,
          pageSize: 10,
          pageNum: 1
        }
    }
  },
  mounted() {
    this.getList()
  }, 
  methods: {
    getList() {
      this.loadingTable = true
      batchList(this.form).then(res => {
        this.loadingTable = false
        console.log(res);
        this.data = res.data.list
        this.total = res.data.total
      })
    },
    customRequest({ file }) {
      console.log(file);
      if (file.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') > -1 || file.type.indexOf('application/vnd.ms-excel') > -1) {
        this.loadingBtn = true
        uploadPhoto(file, excelUrl => {
          batchAdd({excelUrl, purchaserId: this.form.purchaserId}).then(res => {
            this.loadingBtn = false
            if (res.code === 200) {
              this.$message.success(res.message)
              this.form.pageNum = 1
              this.getList()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('请上传正确的格式')
      }
    },
    downLoad() {
      console.log(111);
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}/order/batch/template/download/${this.form.purchaserId}`
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding: 20px;
  background: #fff;
  width: 100%;
  p {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 650;
    margin-bottom: 24px;
  }
  .hint {
    color: #666666;
    font-size: 14px;
    margin-top: 26px;
    margin-bottom: 12px;
    line-height: 18px;
  }
}
.close-circle {
  color: #f5222d;
}
.check-circle{
  color: rgb(132, 0, 255);
}
.loading {
  color: #AAAAAA;
}
.sync {
  color: #52c41a;
}
</style>