var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('p',[_vm._v("批量处理")]),_c('a-row',[_c('a-col',{attrs:{"align":"end","span":24}},[_c('a-space',{attrs:{"size":18}},[_c('a-upload',{attrs:{"disabled":_vm.loadingBtn,"showUploadList":false,"accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'","customRequest":_vm.customRequest}},[_c('a-button',{staticStyle:{"background-color":"rgb(205 0 25 / 85%)"},attrs:{"loading":_vm.loadingBtn,"type":"danger"}},[_c('a-icon',{attrs:{"type":"cloud-upload"}}),_vm._v(" 上传批量订单")],1)],1),_c('a-button',{on:{"click":_vm.downLoad}},[_c('a-icon',{attrs:{"type":"cloud-download"}}),_vm._v(" 下载批量订单模板")],1)],1)],1),_c('a-col',{staticClass:"hint",attrs:{"span":24}},[_c('b',[_vm._v("请先下载批量订单模板，在模板中填入相应的订单信息。在Excel中先搜索开票品类关键词并选中，规格不需要的话可以不填，有需要的则自定义填入，个体户名称通过关键词搜索下拉选中，输入货款金额，选中税率，并输入商品数量 。每个订单创建一行，然后保存文件，并通过页面点击【上传批量订单】。平台会自动对订单进行批量创建，耐心等待几分钟，系统会将本次批量处理的结果进行反馈。")])]),_c('a-col',{attrs:{"span":24}},[_c('a-table',{attrs:{"columns":_vm.columns,"loading":_vm.loadingTable,"data-source":_vm.data,"rowKey":"id","pagination":{
            size: 'middle',
            total: _vm.total,
            current: _vm.form.pageNum,
            pageSize: _vm.form.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '50', '100'],
            showTotal: function (total) { return ("共有 " + total + " 条数据"); },
            showQuickJumper: true,
            onShowSizeChange: function (current, pageSize) {
              _vm.form.pageSize = pageSize
              _vm.form.pageNum = 1
              this$1.getList()
            },
            onChange: function (pageNum) {
              _vm.form.pageNum = pageNum
              this$1.getList()
            }
          }},scopedSlots:_vm._u([{key:"batchStatus",fn:function(text){return [_c('a-space',{class:_vm.batchStatusIcon[text]},[_c('a-icon',{attrs:{"type":_vm.batchStatusIcon[text]}}),_c('span',[_vm._v(_vm._s(_vm.batchStatus[text]))])],1)]}},{key:"reqExcelUrl",fn:function(text){return [_c('a',{attrs:{"href":text,"target":"_blank"}},[_vm._v("下载")])]}},{key:"remark",fn:function(text, row){return [(!row.respExcelUrl)?_c('span',[_vm._v(_vm._s(text))]):_c('a',{attrs:{"href":row.respExcelUrl,"target":"_blank"}},[_vm._v(_vm._s(row.respExcelName))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }